<template>
  <div class="index" @click="showList = false">
    <div id="map"></div>
    <div class="search" @click.stop>
      <card class="search-top">
        <img class="icon" src="@/assets/image/search.png" alt="">
        <input type="text" v-model="searchKey" placeholder="请输入搜索信息" @keyup.enter="searchKeyUp" @focus="showList = true">
      </card>
      <transition name="slide-down">
        <div class="search-list" :style="{height:(windowHeight-630)+'px'}" v-if="showList" v-loading="searchLoading" element-loading-background="rgba(25, 49, 86, 0.85)">
          <vue-scroll v-if="searchList">
            <div class="list-box" v-if="searchList.length > 0">
              <div class="list" v-for="(item,index) in searchList" :key="index" @click="searchDetails(item)">
                <div class="icon">
                  <img v-if="item.type == 1" src="@/assets/image/marker-icon-3.png" alt="">
                  <img v-if="item.type == 2" src="@/assets/image/marker-icon-1.png" alt="">
                  <img v-if="item.type == 3" src="@/assets/image/marker-icon-2-1.png" alt="">
                  <img v-if="item.type == 4" src="@img/party-icon-1.png" alt="">
                </div>
                <div class="name">
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
            <div class="list-no" v-else>什么也没搜到</div>
          </vue-scroll>
          <div class="no-search" v-else>请输入搜索信息并Enter搜索</div>
        </div>
      </transition>
    </div>
    <div class="explain">
      <card class="statistics">
        <div class="s-title">热度前20名商砼站榜单</div>
        <div class="btn" @click="openData">
          <span v-if="!showData">展开</span>
          <span v-else>收起</span>
        </div>
      </card>
      <card class="card">
        <div class="title">标识说明</div>
        <div class="ele-box">
          <div class="ele ele-first">
            <img class="type-1" src="@/assets/image/marker-icon-1.png">
            <span>商砼</span>
          </div>
          <div class="ele ele-first">
            <img class="type-2" src="@/assets/image/marker-icon-2-1.png">
            <span>车辆</span>
          </div>
          <div class="ele ele-first">
            <img class="type-3" src="@/assets/image/marker-icon-3.png">
            <span>矿山</span>
          </div>
          <div class="ele ele-second">
            <div class="item">
              <img src="@/assets/image/level-icon-3.png">
              <span class="color-3">高</span>
            </div>
            <div class="item">
              <img src="@/assets/image/level-icon-2.png">
              <span class="color-2">中</span>
            </div>
            <div class="item">
              <img src="@/assets/image/level-icon-1.png">
              <span class="color-1">低</span>
            </div>
          </div>
        </div>
      </card>
    </div>
    <transition name="slide-fade">
      <div class="data" v-if="showData">
        <card class="card">
          <div class="title">热门商砼站TOP20</div>
          <div class="list-box">
            <div class="list" v-for="(item,index) in dataList" :key="index" @click="goConcreteDetails(item)">
              <div class="index">{{ index+1 }}</div>
              <div class="val"><span :title="item.name">{{ item.name }}</span></div>
            </div>
          </div>
        </card>
      </div>
    </transition>
    <div class="tabs">
      <div 
        class="item" 
        v-for="(item,index) in tabs" 
        :key="index" 
        :class="{active:item.active}"
        @click="changeTabs(item,index)">
        {{ item.name }}
      </div>
    </div>
    <div class="price">
      <transition name="slide-fade">
        <card class="card" style="margin-bottom:12px" v-if="showHistory">
          <div class="card-tabs">
            <div class="title">历史报价</div>
            <i class="el-icon-close" @click="showHistory = false"></i>
          </div>
          <div class="list-title">
            <div class="ele">价格</div>
            <div class="item">用户</div>
            <div class="ele">日期</div>
          </div>
          <div class="list-box" v-if="historyList.length > 0">
            <div class="list" v-for="(item,index) in historyList" :key="index">
              <div class="ele">
                <span>{{ item.goods_price }}</span>
              </div>
              <div class="item" :title="item.nickname">
                <div class="headimg">
                  <img v-if="item.avatar" :src="item.avatar" alt="">
                  <i v-else class="el-icon-s-custom"></i>
                </div>
              </div>
              <div class="ele">
                <span class="time">{{ item.created_at }}</span>
              </div>
            </div>
          </div>
          <div class="no-list" v-else>暂无商品</div>
        </card>
      </transition>
      <transition name="slide-fade">
        <card class="card" v-if="showPrice">
          <div class="card-tabs">
            <div class="title" v-if="priceType == 1">矿山报价</div>
            <div class="title" v-if="priceType == 2">商砼报价</div>
            <div class="tab" v-if="priceType == 2">
              <div class="ele" v-for="(item,index) in price_tab" :key="index" :class="{active:index == price_type_active}" @click="price_type_active = index">{{ item }}</div>
            </div>
          </div>
          <div class="list-title">
            <div class="ele">商品名称</div>
            <div class="ele">价格</div>
          </div>
          <div class="list-box" v-if="priceList.length > 0">
            <div class="list" 
              v-for="(item,index) in priceList" 
              :key="index" 
              :class="{hover:priceType == 2,active:item == price_active}"
              @click="getHistory(item)"
            >
              <div class="ele">
                <span>{{ item.name }}</span>
              </div>
              <div class="ele list-price" v-if="priceType == 2">
                <span class="status" v-if="price_type_active == 0">{{ item.new_price ? item.new_price : '-' }}</span>
                <span class="status" v-if="price_type_active == 1">{{ item.max_price }}</span>
                <span class="status" v-if="price_type_active == 2">{{ item.min_price }}</span>
                <span class="status" v-if="price_type_active == 3">{{ item.price }}</span>
              </div>
              <div class="ele list-price" v-else>
                <span class="status">{{ item.goods_unit_price }}{{ item.company }}</span>
              </div>
            </div>
          </div>
          <div class="no-list" v-else>暂无商品</div>
        </card>
      </transition>
    </div>
    <info-window ref="infoWindow" @goDetails="goDetails" @openPrice="openPrice"></info-window>
  </div>
</template>
<script>
import { lazyAMapApiLoaderInstance } from "vue-amap";
import icons from '@/assets/js/map_icon.js';
export default {
  name:"index",
  data(){
    return {
      showData:false,
      dataList:null,
      tabs:[{name:'矿山',active:true}, {name:'商砼',active:true}, {name:'车辆',active:true},{name:'项目方',active:true}],
      markers_1: [],
      markers_2: [],
      markers_3: [],
      markers_4: [],
      labelsLayer_1:null,
      labelsLayer_2:null,
      labelsLayer_3:null,
      labelsLayer_4:null,
      showPrice:false,
      price_tab:['最新价','最高价','最低价','平均价'],
      price_type_active:0,
      priceId:null,
      priceType:1,
      priceList:[],
      price_active:null,
      showHistory: false,
      historyList:[],
      // 搜索
      windowHeight:0,
      showList:false,
      searchKey:'',
      searchList:null,
      searchLoading:false
    }
  },
  created(){
    this.windowHeight = document.documentElement.clientHeight;
  },
  mounted(){
    this.getData();
    lazyAMapApiLoaderInstance.load().then(()=>{
      this.map = new AMap.Map("map", {
        center: [113.602211,34.796035],
        expandZoomRange: true,
        zooms: [3, 18],
        zoom: 6,
        mapStyle: "amap://styles/ddf1c576d07f43d8ad696a72ca5543a5",
      });
      AMap.plugin(['AMap.Scale',], ()=>{this.map.addControl(new AMap.Scale())});
      this.getMine();
      this.getConcrete();
      this.getDriver();
      this.getParty();
      this.map.on('click',(ev)=>{
        this.showPrice = false;
        this.showHistory = false;
      })
    })
  },
  methods:{
    // 获取商砼top20
    getData(){
      this.$api.getConTop({
        type: 2,
        page:1,
        size:20
      }).then(res=>{
        if(res.code == 1){
          this.dataList = [...res.data.lists];
          this.showData = false;
        }
      })
    },
    openData(){
      this.showPrice = false;
      this.showHistory = false;
      this.showData = !this.showData;
    },
    goConcreteDetails(info){
      this.$router.push({
        name:'mapConcrete',
        params: { id:info.id }
      })
    },
    // 切换tab
    changeTabs(item, index){
      this.map.remove(this.map.getAllOverlays('InfoWindow'));
      if(item.active){
        item.active = false;
        this[`labelsLayer_${index+1}`].remove(this[`markers_${index+1}`]);
      }else{
        item.active = true;
        this[`labelsLayer_${index+1}`].add(this[`markers_${index+1}`]);
      }
      this.map.setFitView();
    },
    // 获取矿山
    getMine(){
      let _this = this;
      // 矿山图层
      this.labelsLayer_1 = new AMap.LabelsLayer({
        zooms: [3, 18],
        zIndex: 300,
        collision: false,
        allowCollision: false,
      });
      this.map.add(this.labelsLayer_1)
      this.$api.mineconList({
        page: 1,
        size: 1000,
        type: 1,
      }).then(res => {
        res.data.lists.forEach(ele => {
          let marker = new AMap.LabelMarker({
            name: ele.name,
            position: [ele.lon, ele.lat],   // 经纬度对象，也可以是经纬度构成的一维数组
            icon: {
              image: icons[`type_3_${ele.level ? (ele.level-1) : 0}`],
              type: 'image'
            },
            offset: new AMap.Pixel(0, 0),
            zIndex: 300
          });
          let infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(0, -50),
            isCustom:true,
            closeWhenClickMap:true
          });
          marker.on('click', (e)=>{
            _this.showPrice = false;
            _this.showHistory = false;
            _this.$refs.infoWindow.initialize({
              type:1,
              title:ele.name,
              color:'#E9310F',
              showPrice:true,
              showDeatils:true,
              showDisable:false,
              ele
            })
            infoWindow.setContent(this.$refs.infoWindow.$el);
            infoWindow.open(_this.map, e.target.getPosition());
          });
          _this.markers_1.push(marker);
        });
        _this.labelsLayer_1.add(this.markers_1)
       
      })
    },
    // 获取商砼
    getConcrete(){
      let _this = this;
      // 矿山图层
      this.labelsLayer_2 = new AMap.LabelsLayer({
        zooms: [3, 18],
        zIndex: 200,
        collision: false,
        allowCollision: false,
      });
      this.map.add(this.labelsLayer_2)
      this.$api.mineconList({
        page: 1,
        size: 1000,
        type: 2,
      }).then(res => {
        res.data.lists.forEach(ele => {
          let marker = new AMap.LabelMarker({
            name: ele.name,
            position: [ele.lon, ele.lat],
            icon: {
              image: icons[ele.is_offer == 1 ? `type_1_1` : `type_1_0`],
              type: 'image'
            },
            offset: new AMap.Pixel(0, -5),
            zIndex: 300
          });
          let infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(0, -42),
            isCustom:true,
            closeWhenClickMap:true
          });
          marker.on('click', (e)=>{
            _this.showPrice = false;
            _this.showHistory = false;
            _this.$refs.infoWindow.initialize({
              type:2,
              title:ele.name,
              color:'#11DB33',
              showPrice:true,
              showDeatils:true,
              showDisable:false,
              ele
            })
            infoWindow.setContent(this.$refs.infoWindow.$el);
            infoWindow.open(_this.map, e.target.getPosition());
          });
          _this.markers_2.push(marker);
        });
        _this.labelsLayer_2.add(this.markers_2)
      })
    },
    // 获取司机
    getDriver(){
      let _this = this;
      this.labelsLayer_3 = new AMap.LabelsLayer({
        zooms: [3, 18],
        zIndex: 100,
        collision: false,
        allowCollision: false,
      });
      this.map.add(this.labelsLayer_3)
      this.$api.carList({
        page: 1,
        size: 1000
      }).then(res => {
        res.data.lists.forEach(ele => {
          if(ele.location){
            let marker = new AMap.LabelMarker({
              name: ele.name,
              position: ele.location.split(','),
              icon: {
                image: icons[`type_2_1`],
                type: 'image'
              },
              offset: new AMap.Pixel(0, 0),
              zIndex: 200
            });
            let infoWindow = new AMap.InfoWindow({
              offset: new AMap.Pixel(0, -40),
              isCustom:true,
              closeWhenClickMap:true
            });
            marker.on('click', (e)=>{
              _this.showPrice = false;
              _this.showHistory = false;
              _this.$refs.infoWindow.initialize({
                type:3,
                title:ele.car_number,
                color:'#00A0E9',
                showPrice:false,
                showDeatils:true,
                showDisable:false,
                ele
              })
              infoWindow.setContent(this.$refs.infoWindow.$el);
              infoWindow.open(_this.map, e.target.getPosition());
            });
            _this.markers_3.push(marker);
          }
          
        });
        _this.labelsLayer_3.add(this.markers_3)
       
      })
    },
    // 获取项目方
    getParty(){
      let _this = this;
      // 矿山图层
      this.labelsLayer_4 = new AMap.LabelsLayer({
        zooms: [3, 18],
        zIndex: 200,
        collision: false,
        allowCollision: false,
      });
      this.map.add(this.labelsLayer_4)
      this.$api.mineconList({
        page: 1,
        size: 1000,
        type: 2,
        commercial_concrete_type: 2
      }).then(res => {
        res.data.lists.forEach(ele => {
          let marker = new AMap.LabelMarker({
            name: ele.name,
            position: [ele.lon, ele.lat],
            icon: {
              image: icons.type_4_1,
              type: 'image'
            },
            offset: new AMap.Pixel(0, -5),
            zIndex: 300
          });
          let infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(0, -30),
            isCustom:true,
            closeWhenClickMap:true
          });
          marker.on('click', (e)=>{
            _this.showPrice = false;
            _this.showHistory = false;
            _this.$refs.infoWindow.initialize({
              type:4,
              title:ele.name,
              color:'#11DB33',
              showPrice:true,
              showDeatils:true,
              showDisable:false,
              ele
            })
            infoWindow.setContent(this.$refs.infoWindow.$el);
            infoWindow.open(_this.map, e.target.getPosition());
          });
          _this.markers_4.push(marker);
        });
        _this.labelsLayer_4.add(_this.markers_4)
      })
    },
    // 搜索
    searchKeyUp(){
      if(this.searchKey.length > 0){
        this.searchLoading = true;
        this.$api.homeSearch({
          keys: this.searchKey,
          type: 1
        }).then(res=>{
          if(res.code == 1){
            this.searchList = [...res.data.info];
          }
        }).finally(() => {
          setTimeout(()=>{
            this.searchLoading = false;
          },500)
        })
      }else{

      }
    },
    searchDetails(item){
      switch(item.type){
        case 1:
          let item1 = this.markers_1.find(ele => {
            return ele.De.name == item.name
          })
          if(item1){
            this.map.setFitView(item1)
          }else{
            this.$router.push({
              name:'mapMine',
              params: { id: item.id }
            })
          }
        break;
        case 2:
          let item2 = this.markers_2.find(ele => {
            return ele.De.name == item.name
          })
          if(item2){
            this.map.setFitView(item2)
          }else{
            this.$router.push({
              name:'mapConcrete',
              params: { id:item.id }
            })
          }
        break;
        case 3:
          let item3 = this.markers_3.find(ele => {
            return ele.De.name == item.name
          })
          if(item3){
            this.map.setFitView(item3)
          }else{
            this.$router.push({
              name:'mapDirver',
              params: { id:item.id }
            })
          }
        break;
        case 4:
          let item4 = this.markers_4.find(ele => {
            return ele.De.name == item.name
          })
          if(item4){
            this.map.setFitView(item4)
          }else{
            this.$router.push({
              name:'mapParty',
              params: { id:item.id }
            })
          }
        break;
      }
    },
    goDetails(info){
      switch(info.type){
        case 1:
        this.$router.push({
          name:'mapMine',
          params: { id: info.ele.id }
        })
        break;
        case 2:
        this.$router.push({
          name:'mapConcrete',
          params: { id:info.ele.id }
        })
        break;
        case 3:
        this.$router.push({
          name:'mapDirver',
          params: { id:info.ele.car_number }
        })
        break;
        case 4:
        this.$router.push({
          name:'mapParty',
          params: { id:info.ele.id }
        })
        break;
      }
    },
    openPrice(info){
      this.priceType = info.type;
      this.showData = false;
      this.priceId = info.ele.id;
      if(info.type == 2){
        this.$api.concreteOffer({
          commercial_concrete_id: info.ele.id,
          page: 1,
          size: 1000
        }).then(res => {
          if(res.code == 1){
            this.price_type_active = 0;
            this.showPrice = true;
            this.priceList = [...res.data.lists]
          }else{
            this.$notify.error({
              title: '错误',
              message: res.msg,
              offset: 100
            });
          }
        }).catch(err =>{
          this.$notify.error({
            title: '错误',
            message: '接口失败，请重试',
            offset: 100
          });
        })
      }else{
        this.$api.goodsList({
          type: info.type,
          type_id: info.ele.id,
          page: 1,
          size: 1000
        }).then(res => {
          if(res.code == 1){
            this.showPrice = true;
            this.priceList = [...res.data.lists]
          }else{
            this.$notify.error({
              title: '错误',
              message: res.msg,
              offset: 100
            });
          }
        }).catch(err =>{
          this.$notify.error({
            title: '错误',
            message: '接口失败，请重试',
            offset: 100
          });
        })
      }
      
    },
    getHistory(info){
      if(this.priceType != 2) return;
      this.price_active = info;
      this.$api.concreteOfferHistory({
        commercial_concrete_id: this.priceId,
        receiving_goods_as_id: info.receiving_goods_as_id
      }).then(res => {
        this.showHistory = true;
        this.historyList = res.data.lists
      }).catch(err =>{
        this.$notify.error({
          title: '错误',
          message: '接口失败，请重试',
          offset: 100
        });
      })
    }
  } 
}
</script>
<style scoped lang='less'>
.index{
  width: 100%;
  overflow: hidden;
}
#map{
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.search{
  position: fixed;
  top: 112px;
  left: 40px;
  z-index: 99999;
  width: 420px;
  height: 50px;
  .search-top{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 24px;
    .icon{
      width: 22px;
      height: 22px;
    }
    input{
      width: 100%;
      height: 100%;
      background: none;
      border: none;
      outline: none;
      padding-left: 40px;
      font-size: 20px;
      color: #FFFFFF;
    }
    input::-webkit-input-placeholder{
      color:rgba(255, 255, 255, 0.6);
    }
  }
  .search-list{
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    top: 62px;
    left: 0;
    background: rgba(25, 49, 86, 0.85);
    .no-search{
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #FFF;
    }
    .list-box{
      padding: 0 24px;
      height: 100%;
      width: 420px;
      box-sizing: border-box;
      .list{
        width: 100%;
        height: 60px;
        border-bottom: 1px solid rgba(2, 22, 52, 0.43);
        cursor: pointer;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        &:hover{
          .name{
            span{
              color: #54FFD9;
            }
          }
        }
        .icon{
          width: 28px;
          height: 28px;
          background: rgba(4, 20, 26, 0.3);
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            max-height: 100%;
            max-width: 100%;
          }
        }
        .name{
          flex: 1;
          overflow: hidden;
          padding: 0 12px;
          display: flex;
          align-items: center;
          span{
            font-size: 20px;
            color: #FFFFFF;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis
          }
        }
      }
    }
    .list-no{
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #FFF;
    }
  }
}

.price{
  width: 430px;
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 99999;
  box-sizing: border-box;
  .card-tabs{
    box-sizing: border-box;
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    padding: 0 12px 0 10px;
    .title{
      font-size: 22px;
      color: #B8E5FA;
      font-weight: 500;
      flex: 1;
      text-align: center;
    }
    .tab{
      width: 280px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ele{
        width: 60px;
        height: 38px;
        background: #043A70;
        border: 1px solid #00AEFF;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #7AC5E8;
        font-weight: 500;
        cursor: pointer;
      }
      .active{
        background: rgba(0, 147, 167, 0.3);
        color: #00F7FF;
        border: 1px solid #00BFEF;
        box-shadow:0 0 8px 0 #0093A7 inset;
      }
    }
    i{
      font-size: 22px;
      color: #B8E5FA;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .list-title{
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    background: #0172A5;
    .ele{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #FFFFFF;
      font-weight: 500;
    }
    .item{
      width: 15%;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #FFFFFF;
      font-weight: 500;
    }
  }
  .list-box{
    padding: 10px;
    min-height: 100px;
    max-height: 230px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .list{
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      padding: 10px 0;
      display: flex;
      .ele{
        flex: 1;
        padding: 0 10px;
        height: 100%;
        color: #59BAF2;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        span{
          max-width: 100%;
          font-size: 20px;
          color: #59BAF2;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .time{
          font-size: 16px;
        }
        .status{
          position: relative;
          padding-right: 24px;
          margin-right: -24px;
        }
        .up{
          &::after{
            content: '';
            display: block;
            width: 14px;
            height: 22px;
            background: url('../../assets/image/home-price-up.png') no-repeat;
            background-size: 100% 100%;
            position: absolute;
            right: 0;
            top: 4px;
          }
        }
        .down{
          &::after{
            content: '';
            display: block;
            width: 14px;
            height: 22px;
            background: url('../../assets/image/homt-price-down.png') no-repeat;
            background-size: 100% 100%;
            position: absolute;
            right: 0;
            top: 4px;
          }
        }
      }
      .item{
        width: 15%;
        height: 100%;
        display: flex;
        align-items: center;
        .headimg{
          width: 30px;
          height: 30px;
          overflow: hidden;
          border-radius: 50%;
          background-color: rgba(0, 147, 167, 0.3);
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 100%;
            height: 100%;
          }
          i{
            color: #fff;
            font-size: 16px;
          }
        }
      }
    }
    .hover{
      cursor: pointer;
      &:hover{
        background: #043A70;
      }
    }
    .active{
      background: #043A70;
    }
  }
  .no-list{
    width: 100%;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 22px;
    color: #59BAF2;
    font-weight: 500;
  }
}

.explain{
  position: fixed;
  bottom: 40px;
  left: 40px;
  z-index: 99999;
  .statistics{
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    .s-title{
      height: 60px;
      font-size: 22px;
      color: #57F7F6;
      padding: 0 20px;
      line-height: 60px;
      position: relative;
      &::before{
        content: '';
        display: block;
        width: 1px;
        height: 20px;
        background: #FFFFFF;
        position: absolute;
        right: 0;
        top: 20px;
      }
    }
    .btn{
      padding: 0 20px;
      height: 60px;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 60px;
      position: relative;
      cursor: pointer;
    }
  }
  .card{
    .title{
      box-sizing: border-box;
      width: 100%;
      font-size: 22px;
      line-height: 30px;
      color: #B8E5FA;
      font-weight: 500;
      padding: 18px;
      text-align: center;
    }
    .ele-box{
      padding: 0 10px 20px;
      display: flex;
      .ele{
        box-sizing: border-box;
        width: 80px;
        height: 194px;
        margin: 0 10px;
        background: url(../../assets/image/home-card-bg.png) no-repeat;
        background-size: 100% 100%;
      }
      .ele-first{
        padding: 40px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .type-1{
          width: 28px;
          height: 41px;
          margin-top: 10px;
        }
        .type-2{
          width: 44px;
          height: 44px;
        }
        .type-3{
          width: 36px;
          height: 42px;
          margin-top: 9px;
        }
        span{
          font-size: 20px;
          line-height: 28px;
          color: #57F7F6;
          font-weight: 500;
        }
      }
      .ele-second{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 23px 0;
        .item{
          width: 100%;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 36px;
            height: 36px;
          }
          span{
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            margin-left: 6px;
          }
          .color-3{
            color: #C02216;
          }
          .color-2{
            color: #DBBD08;
          }
          .color-1{
            color: #57F7F6;
          }
        }
      }
    }
  }
}

.data{
  width: 330px;
  height: 100%;
  position: absolute;
  right: 40px;
  top: 0;
  padding: 100px 0 40px;
  box-sizing: border-box;
  .card{
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }
  .title{
    box-sizing: border-box;
    width: 100%;
    font-size: 22px;
    line-height: 30px;
    color: #B8E5FA;
    font-weight: 500;
    padding: 18px;
    text-align: center;
  }
  .list-box{
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    .list{
      width: 100%;
      height: 5%;
      background: rgba(83, 199, 222, 0.12);
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover{
        .index{
          background: #57F7F6;
          color: #333;
        }
        .val{
          span{
            color: #57F7F6;
          }
        }
      }
      .index{
        width: 50px;
        height: 100%;
        background: #747980;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #FFFFFF;
        font-weight: 500;
      }
      &:nth-child(1){
        .index{
          background: #E04236;
          color: #FFFFFF;
        }
      }
      &:nth-child(2){
        .index{
          background: #CAC401;
          color: #FFFFFF;
        }
      }
      &:nth-child(3){
        .index{
          background: #1C7EF6;
          color: #FFFFFF;
        }
      }
      .val{
        flex: 1;
        height: 100%;
        overflow: hidden;
        padding: 0 10px;
        display: flex;
        align-items: center;
        span{
          font-size: 18px;
          color: #FFFFFF;
          font-weight: 400;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.tabs{
  width: 600px;
  height: 76px;
  position: fixed;
  bottom: 28px;
  left: 50%;
  margin-left: -300px;
  padding: 0 35px;
   z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before{
    content: '';
    display: block;
    width: 34px;
    height: 76px;
    background: url(../../assets/image/home-tab-bar-left.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  &::after{
    content: '';
    display: block;
    width: 34px;
    height: 76px;
    background: url(../../assets/image/home-tab-bar-right.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
  &:hover{
    cursor:pointer
  }
.item{
    width: 150px;
    height: 50px;
    background: url('../../assets/image/home-tab-item.png') no-repeat;
    background-size: 100% 100%;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    line-height: 30px;
    color: #8998AF;
    font-weight: 500;
  }
  .active{
    background: url('../../assets/image/home-tab-item-active.png') no-repeat;
    background-size: 100% 100%;
    color: #FFFFFF;
  }
}

.slide-down-enter-active {
  transition: all .5s ease;
}
.slide-down-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-down-enter, .slide-down-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  height: 0 !important;
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(40px);
  opacity: 0;
}
</style>